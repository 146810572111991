const initialState = {
  isAuthenticated: false,
  isAdmin: false,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        isAuthenticated: true,
        isAdmin: action.isAdmin,
      }
    case 'LOGOUT':
      return initialState
    default:
      return state
  }
}

export default authReducer
