import React, { createContext, useCallback, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Toast } from 'src/components/Toast'
import { CToaster } from '@coreui/react'
import { useLocation } from 'react-router-dom'
import { initialState, toastReducer } from './reducer'

export const ToastContext = createContext()

/**
 * ToastProvider component to manage toast notifications.
 *
 * @param {ReactNode} props.children - Child components.
 * @returns {JSX.Element} The ToastProvider component.
 */
const ToastProvider = ({ children }) => {
  const [toasts, dispatch] = useReducer(toastReducer, initialState)
  const location = useLocation()

  // Adds a toast notification.
  const addToast = useCallback(({ type, title, message, autohide }) => {
    const id = Date.now().toString()
    const toast = { id, type, title, message, autohide }

    dispatch({ type: 'ADD_TOAST', payload: toast })
  }, [])

  // Deletes a toast notification by its ID.
  // const deleteToast = useCallback((id) => dispatch({ type: 'DELETE_TOAST', payload: id }), [])

  // Adds a success toast notification.
  const success = useCallback(
    ({ title, message, autohide }) => addToast({ type: 'success', title, message, autohide }),
    [addToast],
  )

  // Adds an error toast notification.
  const error = useCallback(
    ({ title, message, autohide }) => addToast({ type: 'danger', title, message, autohide }),
    [addToast],
  )

  // Adds an info toast notification.
  const info = useCallback(
    ({ title, message, autohide }) => addToast({ type: 'info', title, message, autohide }),
    [addToast],
  )

  // Adds a warning toast notification.
  const warning = useCallback(
    ({ title, message, autohide }) => addToast({ type: 'warning', title, message, autohide }),
    [addToast],
  )

  // Resets toasts state when location changes
  useEffect(() => dispatch({ type: 'RESET_TOASTS' }), [location])

  return (
    <ToastContext.Provider value={{ success, error, warning, info }}>
      {children}
      <CToaster placement="top-end">
        {toasts.map((toast) => (
          <Toast key={toast.id} toast={toast} />
        ))}
      </CToaster>
    </ToastContext.Provider>
  )
}

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { ToastProvider }
