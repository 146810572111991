import React from 'react'
import PropTypes from 'prop-types'
import { CToast, CToastBody, CToastClose, CToastHeader } from '@coreui/react'

/**
 * Generates JSX for the Toast component based on the provided props.
 *
 * @param {import('.').ToastProps} props - The props object containing data for rendering the Toast component.
 * @returns {React.JSX.Element} - The JSX element representing the Toast component.
 */
export const Toast = ({ toast }) => {
  const { type, title, message, autohide } = toast

  return (
    <CToast
      autohide={autohide ?? true}
      visible={true}
      color={type}
      className="text-white align-items-center"
      placement="top-end"
    >
      {title && (
        <CToastHeader closeButton>
          <div className="fw-bold me-auto">{title}</div>
        </CToastHeader>
      )}
      <div className="d-flex">
        <CToastBody>{message}</CToastBody>
        {!title && <CToastClose className="me-2 m-auto" white />}
      </div>
    </CToast>
  )
}

Toast.propTypes = {
  toast: PropTypes.object.isRequired,
}
