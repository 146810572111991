const initialState = []

/**
 * Reducer function to manage toast state.
 *
 * @param {Array} state - Current state of toasts.
 * @param {Object} action - Action to be performed on the state.
 * @param {string} action.type - Type of action.
 * @param {Object} action.payload - Payload of the action.
 * @returns {Array} New state of toasts.
 */

const toastReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TOAST':
      return [...state, action.payload]
    case 'DELETE_TOAST':
      return state.filter((toast) => toast.id !== action.payload)
    case 'RESET_TOASTS':
      return initialState
    default:
      return state
  }
}

export { initialState, toastReducer }
