import { configureStore } from '@reduxjs/toolkit'
import changeStateReducer from './changeStateReducer'
import authReducer from './authReducer'

const store = configureStore({
  reducer: {
    changeState: changeStateReducer,
    auth: authReducer,
  },
})

export default store
