const initialState = {
  sidebarShow: true,
  showSpinner: false,
}

const changeStateReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'toggleSpinner':
      return { ...state, showSpinner: !state.showSpinner }
    case 'toggleSidebar':
      return { ...state, sidebarShow: !state.sidebarShow }
    default:
      return state
  }
}

export default changeStateReducer
