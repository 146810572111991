import React from 'react'
import PropTypes from 'prop-types'
import { ToastProvider } from 'src/context/ToastProvider'

/**
 * AppProvider component wraps its children with the necessary context providers.
 * The context providers should be listed in descending order of importance
 * @component
 * @example
 * return (
 *   <UserProvider>
 *     <ToastProvider>{children}</ToastProvider>
 *   </UserProvider>
 * )
 *
 * @param {React.ReactNode} props.children - The child components that need to be wrapped by the provider.
 * @returns {React.ReactElement} - A component that wraps its children with the project providers.
 */

const AppProvider = ({ children }) => (
  <>
    <ToastProvider>{children}</ToastProvider>
  </>
)

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppProvider
