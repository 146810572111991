import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import jwt_decode from 'jwt-decode'

const PrivateRoute = () => {
  const token = localStorage.getItem('token')

  const isTokenValid = (token) => {
    if (!token) return false

    try {
      const decodedToken = jwt_decode(token)
      if (decodedToken.exp < Date.now() / 1000) {
        localStorage.removeItem('token')
        return false
      }
      return true
    } catch (error) {
      return false
    }
  }

  if (isTokenValid(token)) {
    return <Outlet />
  } else {
    return <Navigate to="/login" />
  }
}

export { PrivateRoute }
